/* do something */
(function ($) {
    $.fn.initSite = function (method) {
        if (methods[method]) {
            return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
        } else if (typeof method === 'object' || !method) {
            return methods.init.apply(this, arguments);
        }
    };
    var methods = {
        init: function () {
            methods.initPjax();
            methods.initScrollReveal();
            methods.initContactForm();
            methods.initTawk();
            methods.updateMainMenu();
            methods.masonryImagesLoaded();
            //methods.initLogoSlideshow();
        },
        initPjax: function () {

            $.pjax.defaults.maxCacheLength = 0; // need this for back/forward button to force reload content otherwise initialisation breaks

            $(document).pjax('a:not([data-pjax=0])', '#main-container', {timeout: 3000});
            //$(document).pjax('a', '#main-container div:not(.no-pjax-container)', {timeout: 3000});

            $(document).on('pjax:start', function (xhr) {
                NProgress.set(0.4);
                incInterval = window.setInterval(function () {
                    NProgress.inc();
                }, 1000);
                methods.updateMainMenu();
            });

            $(document).on('pjax:complete', function () {
                //debugger
                //mr.documentReady.call($(document),this);
                //mr.windowLoad.call($(window),this);
                // refresh everything
                mr.documentReady.call(this,jQuery);
                mr.windowLoad.call(window,jQuery);
                clearInterval(incInterval);
                NProgress.done();
                methods.initScrollReveal();
                /*setTimeout(function(){
                    mr.masonry.updateLayout.call(); // problem with masonry not refreshing
                },500);*/
                methods.masonryImagesLoaded();

                // $(document).trigger('ready'); //doesnt work
                //$(window).trigger( 'load' );
            });

            /**
             * To make back and forward registerJs functions work
             * @return void
             */
            $(document).on('pjax:end', function (xhr) {
                //console.log('pjax ended');
                clearInterval(incInterval);
                NProgress.done();
            });

            window.addEventListener( "pageshow", function ( event ) {
                var historyTraversal = event.persisted ||
                    ( typeof window.performance != "undefined" &&
                        window.performance.navigation.type === 2 );
                if ( historyTraversal ) {
                    // Handle page restore.
                    window.location.reload();
                }
            });
        },
        updateMainMenu: function(){
            $menu = $("#menu2 .menu-horizontal");
            var $basePathMatch = $menu.find('a[href$="/' + window.location.pathname.split('/')[1] + '"]');
            if ($basePathMatch.length) {
                $menu.find('li').removeClass('active');
                $basePathMatch.closest('li').addClass('active');
            }
        },
        initScrollReveal: function(){

            ScrollReveal().reveal('h1', {
                distance: '50%',
                origin: 'bottom',
                duration: 1200,
                delay: 300
            });
            ScrollReveal().reveal('.slide-up-element', {
                distance: '50%',
                origin: 'bottom',
                duration: 1200,
                delay: 300
            });
            ScrollReveal().reveal('img.slide-up-scroll', {
                distance: '30%',
                origin: 'bottom',
                duration: 2000,
                delay: 400
            });

            ScrollReveal().reveal('.process-2 .process__item', {
                distance: '50%',
                origin: 'bottom',
                duration: 1200,
                delay: 300
            });
        },
        initHome: function () {
            //todo make this work, need to change svg
            methods.animateMainLogo();
            methods.initImacSlideShow();
            methods.animateStockManagement();

        },
        animateMainLogo:function(){
            anime({
                targets: '.subtitle-test path',
                strokeDashoffset: [anime.setDashoffset, 0],
                easing: 'easeInOutSine',
                duration: 1500,
                delay: function (el, i) {
                    return i * 250
                },
                direction: 'alternate',
                loop: true
            });
        },
        initImacSlideShow: function () {
            var timer;
            var curImage = 0;
            var numImages = 5;

            function nextImage() {
                /*console.log('nextimage');
                if (curImage === 0){
                    console.log('adding class');

                }*/

                if (curImage === numImages - 1) {
                    clearTimeout(timer); // only loop through once
                    return;
                    //curImage = 0; // start through loop again
                }


                var e;
                // remove showMe class from current image
                e = document.getElementById("slideimg" + curImage);

                if (typeof e === null || e === null) {
                    clearTimeout(timer); // only loop through once
                    return;
                }

                if (e.dataset.pause) {
                    setTimeout(function () {
                        processTransition(e);
                    }, e.dataset.pause);
                } else processTransition(e);

            }

            function processTransition(e) {
                removeClass(e, "active");

                // compute next image
                curImage++;
                /*if (curImage > numImages - 1) {
                    curImage = 0; // start through loop again
                }*/

                // add active class to next image
                e = document.getElementById("slideimg" + curImage);
                if (e !== null) addClass(e, "active");
            }

            function addClass(elem, name) {
                var c = elem.className;
                if (c) c += " ";  // if not blank, add a space separator
                c += name;
                elem.className = c;
            }

            function removeClass(elem, name) {
                var c = elem.className;
                elem.className = c.replace(name, "").replace(/\s+/g, " ").replace(/^\s+|\s+$/g, "");  // remove name and extra blanks
            }

            function startDesktopPcFade(el) {
                //setTimeout(function(){
                //debugger
                //console.log('starting fade');
                document.getElementById("firstSlideImg").style.opacity = 0;
                addClass(document.getElementById("slideimg0"), 'active');
                timer = setInterval(nextImage, 1500);
                //},1000);
            }

            ScrollReveal().reveal('#firstSlideImg', {
                afterReveal: startDesktopPcFade,
                distance: '30%',
                origin: 'bottom',
                duration: 2000,
                delay: 400
            });
        },
        animateStockManagement: function () {
            /* Hide the cube shapes ready for animation so we can start our animation of it not being there*/
            var cubeShapes = document.querySelectorAll('.stock-svg > g > path');
            cubeShapes.forEach(element => {
                element.style.fillOpacity = 0;
                element.style.strokeOpacity = 0;
            });

            var stockManagement = anime.timeline({
                    // targets: 'svg > g',
                    //translateX: 250,
                    //rotate: '1turn',
                    //backgroundColor: '#FFF',
                    /*duration: 800,
                    delay: anime.stagger(100),*/

                    /*scale: [
                        {value: .1, easing: 'easeOutSine', duration: 500},
                        {value: 1, easing: 'easeInOutQuad', duration: 1200}
                    ],*/

                    delay: anime.stagger(100, {start: 500, easing: 'easeOutQuad'}),
                    loop: true,
                    // direction: 'alternate',
                    // use autoplay to start the animation
                    autoplay: true,
                    // endDelay: 2000,
                })
                // the grouped containers
                    .add({
                        targets: 'svg.stock-svg > g',
                        translateY: 150,
                        delay: anime.stagger(90, {start: 500, easing: 'easeOutQuad'}),
                        // loop: true,
                        // direction: 'alternate',
                    }, 0)
                    // the grouped containers
                    .add({
                        targets: 'svg.stock-svg > g > path',
                        'fill-opacity': 1,
                        'stroke-opacity': 1,
                        delay: anime.stagger(30, {start: 500, easing: 'easeOutQuad'}),
                    }, 0)


                    // Fade in the Text
                    .add({
                        targets: 'svg.stock-svg > g > g > path',
                        'fill-opacity': 1,
                        'stroke-opacity': 1,
                        delay: anime.stagger(20, {start: 0, easing: 'easeOutQuad'}),
                    })

                    /* Move the first top block */
                    .add({
                        targets: 'svg.stock-svg #g1305',
                        translateY: 50,
                        translateX: -404,
                        duration: 1000,
                        delay: 200,
                        easing: 'easeInOutExpo'
                    })


                    /* Make Sold First Block */
                    // top
                    .add({
                        targets: 'svg.stock-svg #g1221 path:nth-child(1)',
                        fill: '#ef8489',
                        duration: 75,
                        delay: 1000,
                        easing: 'easeInOutQuart'
                    })
                    // right side
                    .add({
                        targets: 'svg.stock-svg #g1221 path:nth-child(2)',
                        fill: '#ec6b71',
                        duration: 75,
                        delay: 1,

                        easing: 'easeInOutQuart'
                    })
                    // front
                    .add({
                        targets: 'svg.stock-svg #g1221 path:nth-child(3)',
                        fill: '#e5363e',
                        duration: 75,
                        delay: 1,
                        easing: 'easeInOutQuart'
                    })
                    // fade out text
                    .add({
                        targets: '#flowRoot2085-6',
                        opacity: 0,
                        duration: 50,
                        delay: 1,
                        easing: 'easeInOutQuart'
                    })
                    .add({
                        targets: '#flowRoot2085-5-4-4-4 path',
                        fill: '#ffffff',
                        duration: 1,
                        delay: 1,
                    })
                    // fade in text
                    .add({
                        targets: '#flowRoot2085-5-4-4-4',
                        opacity: 1,
                        duration: 900,
                        delay: 1,
                        easing: 'easeInOutQuart'
                    })

                    /* scale doesnt work here because other attributes on transform element */
                    /*.add({
                        targets: '#flowRoot2085-5-4-4-4',
                        transform:'scale(2)',
                        direction:'alternate',
                        loop:2,
                        duration: 1000,
                        delay:1,
                    })*/


                    /* Make Sold Second Block */
                    .add({
                        targets: 'svg.stock-svg #g1339 path:nth-child(1)',
                        fill: '#ef8489',
                        duration: 100,
                        delay: 1,
                        easing: 'easeInOutQuart'
                    })
                    // right side
                    .add({
                        targets: 'svg.stock-svg #g1339 path:nth-child(2)',
                        fill: '#ec6b71',
                        duration: 100,
                        delay: 1,
                        easing: 'easeInOutQuart'
                    })
                    // front
                    .add({
                        targets: 'svg.stock-svg #g1339 path:nth-child(3)',
                        fill: '#e5363e',
                        duration: 100,
                        delay: 1,
                        easing: 'easeInOutQuart'
                    })
                    .add({
                        targets: '#flowRoot2085-6-8',
                        opacity: 0,
                        duration: 100,
                        delay: 1,
                        easing: 'easeInOutQuart'
                    })
                    .add({
                        targets: '#flowRoot2085-5-4-4 path',
                        fill: '#ffffff',
                        duration: 1,
                        delay: 1,
                    })
                    .add({
                        targets: '#flowRoot2085-5-4-4',
                        opacity: 1,
                        duration: 1000,
                        delay: 1,
                        easing: 'easeInOutQuart'
                    })

                    /* Move the second block */
                    /*.add({
                        targets: 'svg.stock-svg #g1405',
                        translateY: -25,
                        duration: 800,
                        delay: 1000,
                        easing: 'easeInOutExpo'
                    })
                    .add({
                        targets: 'svg.stock-svg #g1405',
                        translateY: -165,
                        translateX: -304,
                        duration: 1500,
                        easing: 'easeInOutExpo',
                        delay: 1,
                    })*/

                    /* Fade Out the whole thing */
                    .add({
                        targets: 'svg.stock-svg > g',
                        opacity: 0,
                        easing: 'easeInOutSine'
                    })

                /*.add({
                    targets: 'svg.stock-svg > g',
                    translateY: -150,
                    delay: anime.stagger(100, {easing: 'easeOutQuad'}),
                })
                .add({
                    targets: 'svg.stock-svg > g > path',
                    'fill-opacity': 0,
                    'stroke-opacity': 0,
                    delay: anime.stagger(20),
                },'-=1800')*/
            ;
        },
        initContactForm(){
            $("body").on("submit","form.contact-form", function(event){
                event.preventDefault();

                var $form = $(this);
                var $parent = $form.parent();
                var formValues= $form.serializeArray();
                var campaignNameVal = window.location.pathname === '/' ? 'Home' : window.location.pathname.substring('1') + ($form.data('form-scenario') ? '-'+$form.data('form-scenario') : '');


                var $submitBtn = $form.find('button');

                $submitBtn.html('Submitting..');
                $submitBtn.prop('disabled',true);

                grecaptcha.ready(function() {
                    grecaptcha.execute('6LfMXkYaAAAAAJ9xyz0aoP1o67uBA1aO_L-ZK48A', {action: 'submit'}).then(function(token) {
                        formValues.push({name:'campaignName',value:campaignNameVal});
                        formValues.push({'name':'recaptchaToken',value:token});

                        $.post("/site/process-form", formValues, function(data){
                            // Display the returned data in browser
                            $parent.html(data);
                        });
                    });
                });
            });
        },
        initTawk: function() {
            if (window.location.href.indexOf("propertyshell.com") > -1) {
                //setTimeout(function() {
                    //console.log('initing');
                    var Tawk_API = Tawk_API || {},
                        Tawk_LoadStart = new Date();
                    (function () {
                        var s1 = document.createElement("script"),
                            s0 = document.getElementsByTagName("script")[0];
                        s1.async = true;
                        s1.src = 'https://embed.tawk.to/592e4cd0b3d02e11ecc67875/default';
                        s1.charset = 'UTF-8';
                        s1.setAttribute('crossorigin', '*');
                        s0.parentNode.insertBefore(s1, s0);
                    })();
                    //tawkLoaded = true;
                    /** Fire Event for Google Analytics
                     this doesn't seem to work **/
                    Tawk_API.onChatStarted = function () {
                        if (Tawk_API.isVisitorEngaged()) {
                            ga('send', 'event', 'tawk-to', 'visitor-engaged');
                            //ga('send', 'event', [eventCategory], [eventAction], [eventLabel], [eventValue], [fieldsObject]);
                            /*window.dataLayer = window.dataLayer || [];
                            window.dataLayer.push({'event': 'chat-started'});*/
                        }
                    };
                //},1500);
            }

            // This doesn't seem to work either as per docs:
            // "Callback function invoked right after the widget is rendered. This callback is not supported in pop out chat window."
            /*Tawk_API.onLoad = function() {
                var pageStatus = Tawk_API.getStatus();
                if (pageStatus !== 'online') {
                    $('.live-chat-link').hide();
                } //else console.log('online');
            }*/
            /*else if(pageStatus === 'away'){
                //do something for away
            }else{
                // do something for offline
            }*/


            /*Tawk_API.onLoad = function(){
                methods.openTawkListener();
            };*/

        },
        masonryImagesLoaded: function($container){
            $('.masonry__container').imagesLoaded()
                .progress( function( instance, image ) {
                    /*var result = image.isLoaded ? 'loaded' : 'broken';
                    console.log( 'image is ' + result + ' for ' + image.img.src );*/
                    mr.masonry.updateLayout.call();
                });

        },
        /*initLogoSlideshow: function(){
            //console.log('hello wolrd');
        return;

            /*function animateCarousel() {
                const carousel = document.getElementById('logo-carousel');
                const firstItem = carousel.firstElementChild;

                // Calculate the width of a single item including its margin
                const itemWidth = firstItem.offsetWidth + parseInt(window.getComputedStyle(firstItem).marginRight);

                // Clone the first item and append it to the end
                const clone = firstItem.cloneNode(true);
                carousel.appendChild(clone);

                let position = 0;

                function moveCarousel() {
                    position--;
                    carousel.style.transform = `translateX(${position}px)`;

                    // If the carousel has moved the width of an item, reset position and move the first item to the end
                    if (position % itemWidth === 0) {
                        carousel.appendChild(carousel.firstElementChild);
                        carousel.style.transform = `translateX(0px)`;
                        position = 0;
                    }

                    requestAnimationFrame(moveCarousel);
                }

                moveCarousel();
            }
            animateCarousel();
            return;*
            let tickerSpeed = 0.8;
            let flickity = null;
            let isPaused = false;
            const slideshowEl = document.querySelector('.logo-carousel');

            const update = () => {
                if (isPaused) return;
                if (flickity.slides) {
                    flickity.x = (flickity.x - tickerSpeed) % flickity.slideableWidth;
                    flickity.selectedIndex = flickity.dragEndRestingSelect();
                    flickity.updateSelectedSlide();
                    flickity.settle(flickity.x);
                }
                window.requestAnimationFrame(update);
            };

            /*const pause = () => {
                isPaused = true;
            };

            const play = () => {
                if (isPaused) {
                    isPaused = false;
                    window.requestAnimationFrame(update);
                }
            };*
            //   Create Flickity
            flickity = new Flickity(slideshowEl, {
                autoPlay: false,
                prevNextButtons: false,
                pageDots: false,
                draggable: true,
                wrapAround: true,
                freeScroll:true,
                selectedAttraction: 0.015,
                friction: 0.25
            });
            //flickity.x = 0;
            //   Add Event Listeners
            /*slideshowEl.addEventListener('mouseenter', pause, false);
            slideshowEl.addEventListener('focusin', pause, false);
            slideshowEl.addEventListener('mouseleave', play, false);
            slideshowEl.addEventListener('focusout', play, false);

            flickity.on('dragStart', () => {
                            isPaused = true;
                        });*
            update();
        }*/
    };
})(window.jQuery);

/*ScrollReveal().reveal('.reveal',{
    delay: 500,
    //useDelay: 'onload',
    reset: true
});*/

/*const svgPath = document.querySelectorAll('.path');

const svgText = anime({
    targets: svgPath,
    loop: true,
    direction: 'alternate',
    strokeDashoffset: [anime.setDashoffset, 0],
    easing: 'easeInOutSine',
    duration: 700,
    delay: (el, i) => { return i * 500 }
});*/

/*anime({
    targets: '.stock-blue g',
    translateX: 250
});*/

/*ScrollReveal().reveal('h1 .grey',{
        distance: '50%',
        origin: 'bottom',
        duration: 1200,
        delay:200
    });*/
