//import './js/jquery-global';
//import $ from 'jquery';

//import './js/smooth-scroll.min.js';
import smoothScroll from './js/smooth-scroll.min.js';
globalThis.smoothScroll = smoothScroll;

// Cannot get any of the flickity js functions to work
/*import flickity from 'flickity/dist/flickity.pkgd.min';
globalThis.flickity = flickity;*/
//require("flickity/js/flickity.js");

//require('flickity.min.js');
// import easyPieChart from './js/easypiechart.min.js';
// globalThis.easyPieChart = easyPieChart;
//import './js/easypiechart.min.js';
// require('parallax.js');
require('./js/typed.min.js');
//require('datepicker.js');
import 'isotope-layout/js/isotope';

//import 'smooth-scroll/dist/smooth-scroll'
//require('ytplayer.min.js');
//require('lightbox.min.js');
//require('granim.min.js');
//require('jquery.steps.min.js');
//require('countdown.min.js');
//require('twitterfetcher.min.js');
//require('spectragram.min.js');

//import 'flickity/dist/flickity.pkgd'

import 'jquery-pjax/jquery.pjax';
import anime from 'animejs/lib/anime';
globalThis.anime = anime;

import NProgress from 'nprogress/nprogress';
globalThis.NProgress = NProgress;

//import './js/scripts.js';
//import './js/pshell.js';
//require('scripts');
//require('pshell');

var _uf = _uf || {};
_uf.domain = ".propertyshell.com";

import './js/utm_form-1.2.0.min.js';

import 'imagesloaded/imagesloaded.pkgd';

import './js/scripts.js';
import './js/pshell.js';

$(document).ready(function () {
    $('body').initSite();
});


// console.log('helllllo scripts');